import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import Link from '../components/Link';
import ExternalIcon from '../assets/icons/external-article.svg';
import LayoutComponent from '../components/Layout';
import Seo from '../components/Seo';
import ObserverSection from '../components/ObserverSection';
import dayjs from '../utils/dayjs';

const sortByCreationDate = (data = []) => {
  const sortedData = data.sort((a, b) => {
    const dateA = dayjs(a.dateGmt);
    const dateB = dayjs(b.dateGmt);
    return dateA.isBefore(dateB);
  });
  return sortedData;
};

const Medias = () => {
  const data = useStaticQuery(graphql`
    query MediaArticlesQuery {
      media: allWpMedia {
        articles: nodes {
          id
          slug
          dateGmt
          medias {
            externalLink
            external
          }
          builder {
            titleFr
            titleEn
            thumbnail {
              localFile {
                url
              }
            }
          }
        }
      }
      access: allWpElelementsAccess {
        nodes {
          visibility {
            showMediaInternalArticles
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const { locale } = intl;
  const [filter, setFilter] = useState(
    data?.access?.nodes?.length > 0 &&
      data?.access?.nodes[0]?.visibility?.showMediaInternalArticles
      ? 'all'
      : 'external'
  );

  const originalData = data?.media?.articles || [];

  const filterMedia = array => {
    if (!array || array.length === 0) {
      return [];
    }

    if (filter === 'internal') {
      const filtered = array.filter(el => !el?.medias?.external);
      return filtered;
    }

    if (filter === 'external') {
      const filtered = array.filter(el => el?.medias?.external === true);
      return filtered;
    }

    return array;
  };

  const filterdMedia = filterMedia(originalData);

  return (
    <LayoutComponent>
      <Seo title={intl.formatMessage({ id: 'media.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'media.title' })}
      </div>
      <div className="page-title-underline" />

      {originalData?.length > 0 &&
        ((data?.access?.nodes?.length > 0 &&
          data?.access?.nodes[0]?.visibility?.showMediaInternalArticles) ||
          data?.access?.nodes?.length === 0) && (
          <div className="media-articles-filters">
            <button
              className={`media-articles-filter-option${
                filter === 'all' ? ' active' : ''
              }`}
              onClick={() => {
                setFilter('all');
              }}
            >
              {intl.formatMessage({ id: 'media.all' })}
            </button>
            <button
              className={`media-articles-filter-option${
                filter === 'internal' ? ' active' : ''
              }`}
              onClick={() => {
                setFilter('internal');
              }}
            >
              {intl.formatMessage({ id: 'media.ourArticles' })}
            </button>
            <button
              className={`media-articles-filter-option${
                filter === 'external' ? ' active' : ''
              }`}
              onClick={() => {
                setFilter('external');
              }}
            >
              {intl.formatMessage({ id: 'media.otherMedia' })}
            </button>
          </div>
        )}
      {(filterdMedia?.length > 0 && (
        <>
          <div className="media-articles-wrapper">
            {sortByCreationDate(filterdMedia).map(el => {
              const Inner = () => (
                <>
                  <div
                    className="article-card-thumbnail"
                    style={{
                      backgroundImage: `url(${el?.builder?.thumbnail?.localFile?.url})`,
                    }}
                  >
                    {el?.medias?.external && (
                      <div className="article-card-external-indicator">
                        <ExternalIcon />
                      </div>
                    )}
                  </div>
                  <div className="article-title-container">
                    <div className="article-title-scroller">
                      <div className="article-card-title">
                        {locale === 'en' && el?.builder?.titleEn
                          ? el.builder.titleEn
                          : el?.builder?.titleFr}
                      </div>
                    </div>
                  </div>
                </>
              );
              return (
                <ObserverSection key={el.id}>
                  {(el.medias.external && (
                    <a
                      className="article-item-card-wrapper"
                      href={el?.medias?.externalLink || '#'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Inner />
                    </a>
                  )) || (
                    <Link
                      className="article-item-card-wrapper"
                      to={`/articles/${el?.slug}`}
                    >
                      <Inner />
                    </Link>
                  )}
                </ObserverSection>
              );
            })}
          </div>
        </>
      )) || (
        <div className="no-media-articles">
          {intl.formatMessage({ id: 'media.noArticles' })}
        </div>
      )}
    </LayoutComponent>
  );
};

export default Medias;
